import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default () =>
  <Layout>

    <div
      className="tc mv4 cf cover bg-center white"
      style={{backgroundImage: 'url(./bg.jpg)'}}
    >
      <div className="bg-black-30 pv6">
        <h1 className="f1 fw4 pa0 ma0 mb2">Connecticut Quasi-Public Organizations</h1>
        <h2 className="f3 mw7 center fw2 pa0 ma0">
          This site provides access to checkbook and payroll information of
          quasi-public organizations in Connecticut. The data are updated yearly.
        </h2>
      </div>
    </div>

    <div className="w-100 w-50-l fl bg-near-white pa3 f4 bn bw0 br-l bw4-l b--white cf mb3">
      <p className="mt0 mb4 h3">
        View quasi-public organization employees, their job titles, and how much they earn.
      </p>

      <Link
        to="/payroll"
        className="db fw2 f4 w-100 pa3 tc link near-white bg-red dim" style={{backgroundColor: '#2D3356'}}>
          Explore Payroll
      </Link>
    </div>

    <div className="w-100 w-50-l fl bg-near-white pa3 f4 bt bn-ns bw4 b--white cf">
      <p className="mt0 mb4 h3">
        View who received payments from the quasi-public organizations
        for goods or services, and how much they received.
      </p>

      <Link
        to="/checkbook"
        className="db fw2 f4 w-100 pa3 tc link near-white bg-red dim" style={{backgroundColor: '#2D3356'}}>
          Explore Checkbook
      </Link>
    </div>
    
  </Layout>
